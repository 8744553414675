import React from "react";
import { Router } from "@reach/router";
import { NumeriseCompanion } from "./numerise/NumeriseCompanion";
import { NotFound } from "./NotFound";

function App() {
  return (
    <Router>
      <NumeriseCompanion path="/numerise" />
      <NotFound default />
    </Router>
  );
}

export default App;
