import React from "react";
import { RouteComponentProps } from "@reach/router";
import appStoreDownload from "./app-store-download.svg";
import numeriseLogo from "./numerise_logo_black.svg";
import styles from "./NumeriseCompanion.module.css";
import { Helmet } from "react-helmet";

const appStoreURL = "https://apps.apple.com/us/app/id1534757891";
const playStoreURL =
  "https://play.google.com/store/apps/details?id=uk.co.sparx.numerisecompanion&utm_source=companionapp-android&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";

export const NumeriseCompanion: React.FC<RouteComponentProps> = () => {
  const gotoAppStore = () => window.open(appStoreURL, "_blank");
  const gotoPlayStore = () => window.open(playStoreURL, "_blank");

  return (
    <div className={styles.Wrapper}>
      <Helmet>
        <title>Numerise Companion</title>
        <meta name="description" content="Maths tutoring with a difference" />
        <link rel="icon" href="/numerise-favicon-32x32.png" />
      </Helmet>

      <img src={numeriseLogo} alt="Numerise" className={styles.Logo} />

      <div className={styles.Container}>
        <h2>Try our new Maths tutor service</h2>
        {/*<span className={styles.TagLine}>Maths tutoring with a difference</span>*/}

        <p>
          Download our app to connect with one of our tutors to get Maths
          support from Home. We have partnered with{" "}
          <a
            href="https://www.numerise.com/?utm_source=companion-link&utm_medium=Website&utm_campaign=disco-companion-trial"
            target="_blank"
            rel="noreferrer"
          >
            numerise.com
          </a>{" "}
          and our tutors will guide your child to Maths work that they can
          complete in <strong>Numerise</strong>.
        </p>

        <div onClick={gotoAppStore} className={styles.AppStoreContainer}>
          <img
            src={appStoreDownload}
            alt="Get it on the Apple App Store"
            className={styles.AppStoreIcon}
          />
        </div>
        <div onClick={gotoPlayStore} className={styles.AppStoreContainer}>
          <img
            className={styles.PlayStoreIcon}
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_gb/badges/static/images/badges/en_badge_web_generic.png"
          />
        </div>

        <h2>Passcode: cat31</h2>
      </div>
      <div className={styles.Container}>
        <h2>How it works</h2>
        <p>
          Our app works by connecting you and your child with a tutor via a
          simple group messaging app. You will need to download an app on both
          your phone and on a mobile device your child can use (such as a phone
          or iPad). The tutor will not be able to contact your child directly,
          and all messages are visible to you. Your personal tutor will keep you
          both up to date with how your child is getting on in{" "}
          <strong>Numerise</strong>, and this helps you and your child feel more
          connected with the work and progress.
        </p>
      </div>
    </div>
  );
};
